.swiper-cards {
        
    .swiper-slide {
        background: transparent !important;
        box-shadow: none;
       
    }
}

.swiper-3d, .swiper-3d.swiper-css-mode .swiper-wrapper {
    perspective: 1800px;
}

.card-box {
    width: 397px;
    background: linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.66%, rgba(255, 255, 255, 0.05) 99.96%);
    backdrop-filter: blur(2px);
    padding: 31px 23px;
    border-radius: 20px;
    border: 1px solid rgba(255,255,255,0.1);
    height: 100%;
    z-index: 3;

    .top {
        margin-bottom: 20px;
        span {
            font-size: 30px;
            margin-right: 13px;
        }

        h6 {
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: -5px;
            color: #584800;

            &.price {
                color: #4682A9;
                margin-top: 5px;
                margin-bottom: 0;
                font-size: 20px;
            }
        }
    }

    .content {
        .image {
            height: 349px;
            border-radius: 20px;
            overflow: hidden;
            margin-bottom: 17px;


            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s ease-in-out;
            }
        }

        .info {
            align-items: center;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 13px;
            }

            h6 {
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 2px;
            }

            p {
                font-weight: 300;
                font-size: 14px;
            }
        }
    }

    &:hover {
        .content {
            .image{
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

}