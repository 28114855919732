.os-proccess-container{
    width: 100%;
    background: white;
}
.os-proccess-title{
    font-size: 60px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    padding: 40px 0;
}

.os-proccess-steps{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.os-proccess-step{
    flex: .2;
    max-width: 20%;
    height: 100%;
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background: rgba(0,119,255,0.4);
}
.os-proccess-step.f1{
    background: rgb(0,119,255);
}
.os-proccess-step.f2{
    background: rgba(0,119,255,0.8);
}
.os-proccess-step.f3{
    background: rgba(0,119,255,0.6);
}
.os-proccess-step.f4{
    background: rgba(0,119,255,0.4);
}
.os-proccess-step.f5{
    background: rgb(0,119,255);
}
.os-proccess-step:hover{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.os-proccess-step-icon{
    width: 60px;
    height: 60px;
    object-fit: contain;
}
.os-proccess-step-title{
    font-size: 26px;
    color: white;
}
.os-proccess-step-desc{
    color: white;
    font-size: 18px;
    text-align: center;
}
.os-proccess-bottom{
    width: 100%;
    padding: var(--global-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background: #00afff;
    padding-top: 50px;
    padding-bottom: 50px;
}
.os-proccess-bottom-title{
    font-size: 60px;
    font-weight: bold;
    color: white;
    width: 70%;
    text-align: center;
}
.os-proccess-bottom-btn {
    position: relative;
    overflow: hidden;
    font-weight: 700;
    background: linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%);
    backdrop-filter: blur(4px);
    padding: 5px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 180px;
    height: 60px;
    text-decoration: none;
    text-transform: uppercase;

}


.os-proccess-bottom-btn span {
    background: linear-gradient(264.28deg, #6bd0ff -38.2%, #91C8E4 103.12%);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    width: 100%;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}


.os-proccess-bottom-btn span::after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.13) 0px, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0));
    content: "";
    height: 200%;
    left: -210%;
    opacity: 0;
    position: absolute;
    top: -50%;
    transition: all 0.7s ease 0s;
    width: 200%;
}

.os-proccess-bottom-btn:hover span::after {
    left: -30%;
    opacity: 1;
    top: -20%;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-property: left, top, opacity;
    transition-timing-function: linear;
}

.os-proccess-bottom-desc{
    width: 90%;
    text-align: center;
    font-size: 30px;
    color: white;
    line-height: 2;
    margin-top: 60px;
    border-top: 1px solid white;
}