.introLoad{
    position: fixed;
    z-index: 999999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #F6F4EB;
    transition: 2s;
    &__logo-header{
        position: absolute;
        z-index: inherit;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #0c79b3;
        display: flex;
        align-items: center;
        justify-content: center;
        &__logo{
            position: relative;
            height: 100px;
            bottom: -20px;
            opacity: 0;
            font-size: 60px;
            font-weight: 800;
            color: #0c79b3;
            z-index: inherit;

            &.active{
                bottom: 0;
                opacity: 1;
                transition: ease-in-out .5s;
            }
            &.fade{
                bottom: 150px;
                opacity: 0;
                transition: ease-in-out .5s;
            }
        }
    }
}