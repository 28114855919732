.footer-container {
    background    : linear-gradient(0deg, #00a6ffc7 5.32%, #F6F4EB 94.32%);
    padding-left  : 5vw;
    padding-right : 5vw;
    width         : 100vw;
    height        : 70vh;
    display       : flex;
    flex-direction: column;
}

.fc-top {
    flex : 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fc-top-left
{
width: 35vw;
display: flex;
flex-direction: column;
gap: 30px;
}
.fc-top-left-title{
    font-size: 60px;
    color: #236e9a;
}

.fc-tl-button {
    position       : relative;
    overflow       : hidden;
    font-weight    : 700;
    background     : linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%);
    backdrop-filter: blur(4px);
    padding        : 5px;
    border-radius  : 10px;
    display        : inline-flex;
    align-items    : center;
    justify-content: flex-start;
    border         : 1px solid rgba(255, 255, 255, 0.1);
    width          : 180px;
    height         : 60px;
    text-decoration: none;
}

.fc-tl-button span {
    background     : linear-gradient(264.28deg, #236e9a -38.2%, #91C8E4 103.12%);
    padding        : 10px 20px;
    border-radius  : 10px;
    color          : #fff;
    width          : 100%;
    transition     : all 0.3s ease-in-out;
    position       : relative;
    overflow       : hidden;
    height         : 100%;
    display        : flex;
    align-items    : center;
    justify-content: center;
    font-size      : 18px;

}

.fc-tl-button span::after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.13) 0px, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0));
    content   : "";
    height    : 200%;
    left      : -210%;
    opacity   : 0;
    position  : absolute;
    top       : -50%;
    transition: all 0.7s ease 0s;
    width     : 200%;
}

.fc-tl-button:hover span::after {
    left                      : -30%;
    opacity                   : 1;
    top                       : -20%;
    transition-duration       : 0.7s, 0.7s, 0.15s;
    transition-property       : left, top, opacity;
    transition-timing-function: linear;
}


.fc-top-right
{
    flex: .5;
}

.fc-bottom {
    height: 30px;
    width : 100%;
    border-top: 2px solid #236e9a;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fc-bottom-left{
    font-size: 14px;
    font-weight: lighter;
    color: #236e9a;

}

@media screen and (max-width:1080px) {
    .fc-top-left-title{
        font-size: 40px;
    }
    .footer-container{
        min-height: 70vh;
        height: auto;
    }
}
@media screen and (max-width:740px) {
    .fc-top{
        flex-direction: column;
    }
    .fc-top-left{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
}