.of-banner-container{
    width: 100%;
    padding: var(--global-padding);
    padding-top: 140px;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    gap: 80px;
}
.of-banner-left{
    flex: .5;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.of-banner-left-title{
    font-size: 100px;
    font-weight: bold;
    color: #333333;
}
.of-banner-left-subtitle{
    font-size: 30px;
    font-weight: lighter;
    color: black;
}
.of-banner-left-desc{
    font-size: 20px;
    font-weight: normal;
    color: #8a919c;
    line-height: 1.5;
    letter-spacing: 1.5px;
}
.of-banner-left-button {
    position: relative;
    overflow: hidden;
    font-weight: 700;
    background: linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%);
    backdrop-filter: blur(4px);
    padding: 5px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 180px;
    height: 60px;
    text-decoration: none;
    text-transform: uppercase;

}


.of-banner-left-button span {
    background: linear-gradient(264.28deg, #6bd0ff -38.2%, #91C8E4 103.12%);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    width: 100%;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}


.of-banner-left-button span::after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.13) 0px, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0));
    content: "";
    height: 200%;
    left: -210%;
    opacity: 0;
    position: absolute;
    top: -50%;
    transition: all 0.7s ease 0s;
    width: 200%;
}

.of-banner-left-button:hover span::after {
    left: -30%;
    opacity: 1;
    top: -20%;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-property: left, top, opacity;
    transition-timing-function: linear;
}

.of-banner-right{
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.of-banner-right-img{
    object-fit: cover;
    width: 80%;
}