
@import url('https://fonts.cdnfonts.com/css/conthrax');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap');

body {
  --bg-main-color:#F6F4EB;
  --bg-main-color-darker:#F6F4EB;
  --global-padding:30px 100px;

  font-family: 'Readex Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

  /* @media screen and (min-width: 4001px) {
       .hero-container{
        zoom: 2.5;
       }
  }
  @media screen and (min-width: 2501px) and (max-width: 4000px) {
    body{
      zoom: 1.75;
     }
  }
  @media screen and (min-width: 1600px) and (max-width: 2500px) {
    body{
      zoom: 1.5;
     }
  }è
  @media screen and (max-width: 1599px) {
    body{
      zoom: 1;
     }
  } */
  html {
    scroll-behavior: smooth;
  }