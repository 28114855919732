.our-client-container {
    background     : linear-gradient(0deg, #F6F4EB 1.32%, #91C8E4 94.32%);

    width          : 100vw;
    display        : flex;
    align-items    : center;
    justify-content: center;
    flex-direction : column;
    gap: 40px;
    padding: var(--global-padding);
}

.occ-title { 
    text-align : center;
    color      : #333333;
    font-size  : 60px;
    font-weight: lighter;
}

.occ-desc {
    text-align : center;
    color      : #0c79b3;
    font-size  : 30px;
    font-weight: lighter;
}

@media screen and (max-width:1080px) {
    .occ-title{
        font-size: 40px;

    }
    .occ-desc{
        font-size: 25px;
    }
}
@media screen and (max-width:720px) {
    .occ-title{
        font-size: 30px;

    }
    .occ-desc{
        font-size: 18px;
    }
}
