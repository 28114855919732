.data-laws-container{
    min-height: 100vh;
    width: 100vw;
    padding: 40px 10vw;
    background: linear-gradient(0deg,  #F6F4EB -38.2%,#F6F4EB 103.12% );
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.dlc-item{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
}
.dlc-item-title{
    font-size: 50px;
    color: #0c79b3;
}
.dlc-item-desc{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
}
.dlc-item-desc-item{
    font-size: 16px;
    font-weight: lighter;
    letter-spacing: 1.5px;
    line-height: 2;
}
.dlc-item-desc-item:last-child{
    margin-bottom: 20px;
}
.dlc-item-desc-item span{
    font-weight: normal;
}
.dlc-id-list{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.dlc-id-list-item{
    margin-left: 40px;
    font-weight: lighter;
}