.of-advantages-container{
    width: 100%;
    padding: var(--global-padding);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.of-advantages-left{
    flex: 0.3;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.of-advantages-left-subtitle{
    font-size: 30px;
    font-weight: lighter;
    color: #8a919c;
}
.of-advantages-left-title{
    font-size: 60px;
    font-weight: bold;
    color: #333333;
}
.of-advantages-center{
    flex: 0.3;
    max-width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.of-advantages-center-img{
    object-fit: cover;
    width: 100%;
}
.of-advantages-right{
    flex: 0.3;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;

}
.of-advantages-right-desc{
    font-size: 20px;
    font-weight: normal;
    color: #8a919c;
    line-height: 1.5;
    letter-spacing: 1.5px;
}