.navbar-container {
    height                 : 100px;
    width                  : 100vw;
    /* background             : rgba(30, 104, 143, 0.88); */
    /* background: transparent; */
    position               : fixed;
    top                    : 0;
    display                : flex;
    align-items            : flex-end;
    justify-content        : space-between;
    padding                : 10px 5vw;
    transition             : all .5s ease;
    box-shadow             : 0 4px 30px rgba(0, 0, 0, 0.1);
    background             : rgba(30, 104, 143, 0.55);
    backdrop-filter        : blur(3.8px);
    -webkit-backdrop-filter: blur(3.8px);
    z-index: 5;
    overflow: hidden;
}

.navbar-container.scroll {
    background             : rgba(30, 104, 143, 0.55);
    backdrop-filter        : blur(3.8px);
    -webkit-backdrop-filter: blur(3.8px);
    height                 : 80px;
    align-items            : center;

}

.nc-left {
    display    : flex;
    align-items: center;
    gap        : 40px;
}

.nc-left-logo {
    height: 60px;
    cursor: pointer;
}

.nc-left-nav-list {
    list-style : none;
    margin     : 0;
    padding    : 0;
    display    : flex;
    align-items: center;
    gap        : 40px;
}

.nc-ln-li {
    display       : flex;
    align-items   : center;
    font-size     : 20px;
    cursor        : pointer;
    font-weight   : lighter;
    line-height   : 2;
    padding-bottom: 5px;
    position      : relative;
    transition    : all .3s ease;
    color         : white;
}


.nc-ln-li:hover {
    color: var(--bg-main-color-darker);
}

.nc-ln-li:after {
    content         : '';
    display         : block;
    position        : absolute;
    bottom          : 0;
    transform       : scaleX(0);
    /* border-bottom: 2px solid red; */
    background      : var(--bg-main-color-darker);
    transition      : transform 0.5s ease;
    height          : 2px;
    transform-origin: center;
    /* Animate from right to left */
}

.nc-ln-li:hover:after {
    transform: scaleX(1);
    width    : 100%;

}

.nc-right {
    display    : flex;
    align-items: center;
    gap        : 50px;

}

.nc-right-nav-button {
    border       : 1px solid var(--bg-main-color-darker);
    outline      : none;
    height       : 50px;
    width        : 180px;
    border-radius: 60px;
    color        : var(--bg-main-color-darker);
    background   : transparent;
    cursor       : pointer;
    font-size    : 20px;
    font-weight  : lighter;
    line-height  : 2;
    transition   : all .3s ease;
}

.nc-right-nav-button.reverse {
    border    : none;
    color     : white;
    background: var(--bg-main-color-darker);
    transition: all .3s ease;
}

.nc-right-nav-button:hover {
    transform: scale(.9);
}

.nc-right-button {
    position: relative;
    overflow: hidden;
    font-weight: 700;
    background: linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%);
    backdrop-filter: blur(4px);
    padding: 5px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 180px;
    height: 60px;
    text-decoration: none;
}
.nc-right-button span {
    background: linear-gradient(264.28deg, #6bd0ff -38.2%, #91C8E4 103.12%);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    width: 100%;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    
}
.nc-right-button span::after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.13) 0px, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0));
    content: "";
    height: 200%;
    left: -210%;
    opacity: 0;
    position: absolute;
    top: -50%;
    transition: all 0.7s ease 0s;
    width: 200%;
}
.nc-right-button:hover span::after {
    left: -30%;
    opacity: 1;
    top: -20%;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-property: left, top, opacity;
    transition-timing-function: linear;
}

.nc-right-burger.MuiSvgIcon-root{
    display: none;
    font-size: 50px;
    color: #F6F7EB;
    cursor: pointer;
}

.navbar-container-mobile{
    display: none;
}

@media screen and (max-width:1000px) {
    .nc-left-nav-list{
        display: none;
    }
    .nc-right-button{
        display: none;
    }
    .nc-right-burger.MuiSvgIcon-root{
        display: flex;
    }
    .navbar-container-mobile{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        transform: translateY(-100%);
        transition: all .3s ease;
        z-index: 6;
        display: flex;
    }
    .navbar-container-mobile.active{
        opacity: 1;
        background             : rgba(30, 104, 143, 0.55);
        backdrop-filter        : blur(3.8px);
        -webkit-backdrop-filter: blur(3.8px);
        transform: translateY(0);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 30px;
        overflow-y: hidden;
    }
    .nc-left-nav-list.mobile{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        
    }
    .nc-left-nav-list.mobile .nc-ln-li{
        font-size: 30px;
    }
    .nc-right-button.mobile{
        display: flex;
    }
    .nc-ma-close.MuiSvgIcon-root{
        position: absolute;
        right: 50px;
        top: 50px;
        font-size: 50px;
        color: #F6F7EB;
        cursor: pointer;
    }
}

