.of-services-container{
    background: #f8fcfe;
    width: 100%;
    padding: var(--global-padding);
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
}
.of-services-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.of-sevices-card{
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 40px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)  ;
}
.of-services-car-image{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
}
.of-services-car-title{
    font-size: 30px;
    font-weight: bold;
    color: #333333;
}
.of-services-car-desc{
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 1.5px;
    font-weight: lighter;
    color: #8a919c;
    text-align: center;
}
.of-services-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 100%;
}