.banner-container { 
    height             : 100vh;
    width              : 100vw;
    background-image   : url("../images//icon/bg-hero-curve.png");
    background-position: bottom left;
    background-size    : cover;
    padding            : var(--global-padding);
    display            : flex;
    align-items        : center;
    gap                : 50px;
}

.banner-container-left {
    flex          : .5;
    display       : flex;
    flex-direction: column;
    gap           : 40px;
    align-self: flex-start;
}

.banner-container-left-buttons {
    display      : flex;
    align-items  : center;
    height       : 60px;
    width: 70%;
    border-radius: 40px;
    border       : 1px solid #333333;
    background   : transparent;
}

.banner-container-left-buttons-btn {
    padding      : 10px 20px;
    flex           : .5;
    background     : transparent;
    display        : flex;
    align-items    : center;
    justify-content: center;
    height         : 100%;
    width          : 50%;
    outline: none;
    border: none;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 24px;
    cursor: pointer;
    color        : #424242;
    transition: all .3s ease;
}
.banner-container-left-buttons-btn.active{
    background: #0c79b3;
    border-radius: 40px 0 0 40px;
    color: white;
    box-shadow: 0px 21px 22px -14px #0c79b3;

}

.banner-container-left-buttons-btn.active.left{
    border-radius: 0 40px 40px 0;
}

.banner-container-left-content{
    display: flex;
    flex-direction: column;
    gap: 40px;
    transition: all .3s ease-in-out;
}

.banner-container-left-content-heading{
    font-size: 60px;
    color: #333333;
}
.banner-container-left-content-heading span{
    font-size: 60px;
    color: #0c79b3;
}
.banner-container-left-content-desc{
    font-size: 18px;
    color: #949ba6;
}



.banner-container-left-content-button {
    position: relative;
    overflow: hidden;
    font-weight: 700;
    background: linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%);
    backdrop-filter: blur(4px);
    padding: 5px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 180px;
    height: 60px;
    text-decoration: none;
    text-transform: uppercase;

}


.banner-container-left-content-button span {
    background: linear-gradient(264.28deg, #6bd0ff -38.2%, #91C8E4 103.12%);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    width: 100%;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}


.banner-container-left-content-button span::after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.13) 0px, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0));
    content: "";
    height: 200%;
    left: -210%;
    opacity: 0;
    position: absolute;
    top: -50%;
    transition: all 0.7s ease 0s;
    width: 200%;
}

.banner-container-left-content-button:hover span::after {
    left: -30%;
    opacity: 1;
    top: -20%;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-property: left, top, opacity;
    transition-timing-function: linear;
}


.banner-container-right {
    flex: .5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
}
.banner-container-right-img{
    height: 100%;
    object-fit: contain;
}
