
.about-container{
    background: #F6F4EB;
    padding-left: 5vw;
    padding-right: 5vw;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-image: url("../images/icon/108347-blue-and-white-curve.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    
}


.ac-sub-heading {
    padding: 10px ;
    background: linear-gradient(264.28deg, #6bd0ff -38.2%, #91C8E4 103.12%);

    backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 100px;
    display: inline-block;
    width: 180px;
    height: 60px;
    margin-top: 20px;
    
}
.ac-sub-heading span {
    background: linear-gradient(264.28deg, #6bd0ff -38.2%, #91C8E4 103.12%);
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    /* background-clip: text; */
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 30px;
    color: #F6F4EB;
    padding: 10px;
}
.ac-heading{
    font-size: 45px;
    color: white;
}
.ac-content{
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ac-content-left{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: .5;
    height: 100%;

}
.ac-content-left-image{
    width: 30vw;
    animation: float 3s ease-in-out infinite ;
}
@keyframes float {
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-30px);
    }
    100%{
        transform: translateY(0);
    }
}
.ac-content-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: .5;
    height: 100%;
    gap: 60px;
}
.ac-content-right-desc{
    font-size: 22px;
    color: #151515;
    
}
.ac-content-right-title{
    font-size: 45px;
    color: #0c79b3;
}

@media screen and (max-width:1000px) {
    .ac-content{
        flex-direction: column;
    }
}
@media screen and (max-width:720px) {
    .ac-content-left{
        margin-top: 20px;
    }
}