
.contact-container{
    background: #F6F4EB;
    padding-left: 5vw;
    padding-right: 5vw;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-bottom: 40px;
}
.cc-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 5vw;
    gap: 40px;
}
.ccm-title{
    font-size: 60px;
    color: #0c79b3;
}
.ccm-desc{
    font-size: 30px;
    font-weight: lighter;
    color: #749BC2;
}
.ccm-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.ccm-form-row{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
}

.ccm-fr-item{
    flex: .5;
}
.ccm-fr-item.full{
    flex: 1;
}
.nc-right-button.ccm{
    align-self: center;
    width: 200px;
    height: 60px;
    cursor: pointer;
    display: block;
}
@media screen and (max-width:720px) {
    .ccm-title{
        font-size: 40px;

    }
    .ccm-desc{
        font-size: 22px;
    }
    .ccm-form{
        gap: 20px;
    }
    .ccm-form-row{
        flex-direction: column;
    }
    .ccm-fr-item{
        flex: 1;
        width: 100%;
    }
}