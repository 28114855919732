.reviews-section-container {
    width           : 100%;
    padding         : var(--global-padding);
    padding-top     : 60px;
    padding-bottom  : 60px;
    display         : flex;
    flex-direction  : column;
    gap             : 40px;
    background-image: url("../images/icon/bg-curve-request-sec.jpg");
}

.reviews-section-title {
    font-size  : 60px;
    font-weight: bold;
    color      : #333333;
    text-align : center;
}

.reviews-section-card-container {
    padding        : 40px 0;
    width          : 100%;
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.reviews-section-card {
    width          : 90%;
    padding        : 60px;
    background     : white;
    border         : 3px solid #e6e6e6;
    border-radius  : 20px;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    gap            : 20px;
    position       : relative;
}

.reviews-section-card-owner {
    font-size  : 20px;
    font-weight: bold;
    color      : black;
    text-align : center;
}

.reviews-section-card-job {
    font-size  : 26px;
    font-weight: lighter;
    color      : #333333;
    text-align : center;
}

.reviews-section-card-review {
    font-size     : 18px;
    letter-spacing: 1.5px;
    color         : black;
    text-align    : center;
    width         : 80%;
    position      : relative;
}

.reviews-section-card-review::before {
    content    : "\f10d";
    position   : absolute;
    font-family: 'Font Awesome 6 Free';
    left       : 0px;
    top        : -10px;
    font-weight: 900;
    font-size  : 35px;
    color      : #8a919c;
}

.reviews-section-card-review::after {
    right      : 0;
    font-weight: 900;
    font-size  : 35px;
    position   : absolute;
    font-family: 'Font Awesome 6 Free';
    bottom     : -10px;
    content    : "\f10e";
    color      : #8a919c;
}

.reviews-section-card-img-container {
    position       : absolute;
    height         : 80px;
    width          : 80px;
    top            : 0;
    left           : 50%;
    transform      : translate(-50%, -50%);
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.reviews-section-card-img {
    object-fit   : cover;
    height       : 100%;
    width        : 100%;
    border-radius: 50%;
}
.reviews-section-btn {
    align-self: center;
    position: relative;
    overflow: hidden;
    font-weight: 700;
    background: linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%);
    backdrop-filter: blur(4px);
    padding: 5px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid rgba(255, 255, 255, 0.1);
    height: 60px;
    text-decoration: none;
    text-transform: uppercase;

}


.reviews-section-btn span {
    background: linear-gradient(264.28deg, #6bd0ff -38.2%, #91C8E4 103.12%);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    width: 100%;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}


.reviews-section-btn span::after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.13) 0px, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0));
    content: "";
    height: 200%;
    left: -210%;
    opacity: 0;
    position: absolute;
    top: -50%;
    transition: all 0.7s ease 0s;
    width: 200%;
}

.reviews-section-btn:hover span::after {
    left: -30%;
    opacity: 1;
    top: -20%;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-property: left, top, opacity;
    transition-timing-function: linear;
}